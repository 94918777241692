import { useAppContext } from '@Contexts/contexts';

/**
 * Returns the fallback image source for hotel images, which is used when the hotel image is not available.
 */
export function useImageFallbackSrc() {
  const { publicPath } = useAppContext();

  return `${publicPath}Images/placeholder-hotel-image.png`;
}
